// PDFViewer.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

const PDFViewer: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const pdfUrl = queryParams.get('data');

  const decodedUrl = atob(pdfUrl as string);

  const [numPages, setNumPages] = useState<number>();
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  // @ts-ignore
  function onPageSize({ width }) {
    console.log('scale', width)
    const scaleFactor = window.innerWidth / width;
    setScale(prevScaleFactor => {
      if (Math.abs(prevScaleFactor - scaleFactor) > 100) {
        return scaleFactor
      }
      return prevScaleFactor
  
    });
  }

  useEffect(() => {
    if (numPages && numPages > 0) {
    const resizeListener = () => {
      // Re-calculate scale on window resize
      // The document's first page will be used as reference for resizing
        // Use a debounce function if this causes performance issues
        console.log('hi')
        document.querySelector('.react-pdf__Page canvas')?.dispatchEvent(new Event('pageresize'));
      }
      
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    };
  }, [numPages]);



  // console.log(pdfUrl)

  useEffect(() => { 
    
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //   'pdfjs-dist/build/pdf.worker.min.mjs',
    //   // import.meta.url,
    // ).toString();

    // pdfjs.GlobalWorkerOptions.workerSrc
    // pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

}, []);

  if (!decodedUrl) {
    return <div>No PDF URL provided!</div>;
  }

  return (
    <div className="pdf-container">
      <Document
        file={decodedUrl        }
        onLoadError={console.error}
        loading={<div>Loading PDF...</div>}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...Array(numPages).keys()].map((el, index) => (
                  <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  scale={scale}
                  width={window.innerWidth} // This will automatically scale the page to fit the container width
                  onLoadSuccess={index === 0 ? onPageSize : undefined} // Pass `undefined` for pages other than the first
                />
        ))}
      </Document>

    </div>
  );
};

export default PDFViewer;
