import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PDFViewer from './PDFViewer';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/pdf" element={<PDFViewer />} />
      {/* 여기에 추가적인 라우트를 설정할 수 있습니다. */}
      {/* <Route path="/test" element={<p>{'asdasddsadada'}</p>} /> */}
    </Routes>
  );
}

export default App;
